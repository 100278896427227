import React, { useState, useEffect } from "react";
import { Paper, TextField, Divider, Badge } from "@mui/material";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getCompanies as getCompanies,
  getUnverifiedUserList,
  notifyUsers,
  getCompanyList,
  updateUserPassword,
  editCompanyDetails,
  getReprocessTableSize
} from "../apis/adminUtils";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";
import AdminAuth from "../hooks/AdminAuth";
import SnackBar from "../components/SnackBar";
import Topbar from "../components/Topbar";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import UserGuard from "../hooks/UserGuard";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { BACKEND_PROD_URL } from "../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminUtils = () => {
  const [severity, setSeverity] = useState("Success");
  const [parentLoading, setParentLoading] = useState(true);
  const [unverifiedUserCount, setUnverifiedUserCount] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { currentUser } = useAuth();


  const [openNotifyUserDialog, setOpenNotifyUserDialog] = useState(false);
  const [openUnverifiedListDialog, setOpenUnverifiedListDialog] = useState(false);
  const [openChangeUserPasswordDialog, setOpenChangeUserPasswordDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openDeleteNotificationDialog, setOpenDeleteNotificationDialog] = useState(false);
  const [openFailedReprocessQrsDialog, setOpenFailedReprocessQrsDialog] = useState(false);

  useEffect(() => {
    if (currentUser.userrole === "ADMIN") {
      async function unverifiedUserList() {
        try {
          const res = await getUnverifiedUserList();
          // console.log(res.length);
          setUnverifiedUserCount(res.length);
        } catch (error) {
          if (error.response) {
            setOpenSnackbar(true);
            setSnackbarMessage(error.response.data.message);
          } else {
            setOpenSnackbar(true);
            setSnackbarMessage(error.message);
          }
        }
        setParentLoading(false);
      }

      unverifiedUserList();
    }
  }, []);

  const handleClickOpen = (dialog) => {
    if (dialog === "notifyUser") {
      setOpenNotifyUserDialog(true);
    }
    if (dialog === "unverifiedUsers") {
      setOpenUnverifiedListDialog(true);
    }
    if (dialog === "changePassword") {
      setOpenChangeUserPasswordDialog(true);
    }
    if (dialog === "editUser") {
      setOpenEditUserDialog(true);
    }
    if (dialog === "deleteNotification") {
      setOpenDeleteNotificationDialog(true);
    }
    if (dialog === "reprocessFailedQrs") {
      setOpenFailedReprocessQrsDialog(true);
    }
  };

  const handleClose = (dialog) => {
    if (dialog === "notifyUser") {
      setOpenNotifyUserDialog(false);
    } else if (dialog === "unverifiedUsers") {
      setOpenUnverifiedListDialog(false);
    } else if (dialog === "changePassword") {
      setOpenChangeUserPasswordDialog(false);
    } else if (dialog === "editUser") {
      setOpenEditUserDialog(false);
    } else if (dialog === "deleteNotification") {
      setOpenDeleteNotificationDialog(false);
    }
    else if (dialog === "reprocessFailedQrs") {
      setOpenFailedReprocessQrsDialog(false);
    }
  };

  const NotifyUserDialog = () => {
    const [selectedCompanies, setSelectedCompanies] = useState();
    const [companiesList, setCompaniesList] = useState([]);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false);
    const [localSnackbarMessage, setLocalSnackbarMessage] = useState("");
    const [notificationType, setNotificationType] = useState("General");

    useEffect(() => {
      const callCompaniesListApi = async () => {
        setLoading(true);
        try {
          console.log("Calling emails api", currentUser, currentUser.email);
          let companies = await getCompanies({ email: currentUser.email });
          companies = [{ company: "All" }, ...companies];
          setLocalSnackbarMessage("Fetched companies list successfully");
          setSeverity("success");
          setOpenLocalSnackbar(true);
          setCompaniesList(companies);
        } catch (error) {
          console.log(error);
          setLocalSnackbarMessage(
            "Unable to process request. Internal server occurred"
          );
          setSeverity("error");
          setOpenLocalSnackbar(true);
        }
        setLoading(false);
      };

      if (openNotifyUserDialog) {
        callCompaniesListApi();
      }
    }, [openNotifyUserDialog]);

    const handleNotificationTypeChange = (event) => {
      setNotificationType(event.target.value);
    };

    const handleSubmit = async () => {
      try {
        let finalCompanyList = selectedCompanies;
        const containsAll = selectedCompanies.some(
          (company) => company === "All"
        );
        console.log(containsAll);
        if (containsAll) {
          console.log("Contains all");
          finalCompanyList = companiesList
            .filter((company) => company.company !== "All")
            .map((company) => company.company);
        }
        console.log(finalCompanyList);
        const submitNotifyRequest = await notifyUsers({
          userList: finalCompanyList,
          title: title,
          message: message,
          notificationType: notificationType,
        });
        setLocalSnackbarMessage("Request submitted successfully");
        setSeverity("success");
        setOpenLocalSnackbar(true);
      } catch (error) {
        console.log(error);
        setLocalSnackbarMessage(
          "Unable to process request. Internal server occurred"
        );
        setSeverity("error");
        setOpenLocalSnackbar(true);
      }
      handleClose("notifyUser");
    };

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Dialog
              fullScreen
              open={openNotifyUserDialog}
              onClose={() => handleClose("notifyUser")}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => handleClose("notifyUser")}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Notify User
                  </Typography>
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={() => handleSubmit()}
                  >
                    submit
                  </Button>
                </Toolbar>
              </AppBar>
              <Autocomplete
                multiple
                id="emails"
                options={companiesList.map((option) => option.company)}
                value={selectedCompanies}
                freeSolo
                onChange={(event, newValue) => setSelectedCompanies(newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Users"
                    placeholder="Select Users"
                  />
                )}
                sx={{ margin: 2 }}
              />

              <FormControl sx={{ margin: 2 }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={notificationType}
                  label="Type"
                  onChange={handleNotificationTypeChange}
                >
                  <MenuItem value={"General"}>General</MenuItem>
                  <MenuItem value={"Subscription"}>Subscription</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="outlined-multiline-static"
                label="Title"
                sx={{ margin: 2 }}
                onChange={(event) => setTitle(event.target.value)}
                value={title}
              />
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                sx={{ margin: 2 }}
                onChange={(event) => setMessage(event.target.value)}
                value={message}
              />
            </Dialog>
          </>
        )}
        <SnackBar
          open={openLocalSnackbar}
          setOpen={setOpenLocalSnackbar}
          message={localSnackbarMessage}
          severity={severity}
        />
      </div>
    );
  };

  const UnverifiedUserListDialog = () => {
    const [loading, setLoading] = useState(true);
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false);
    const [localSnackbarMessage, setLocalSnackbarMessage] = useState("");
    const [deleteUserData, setDeleteUserData] = useState({});
    const [unverifiedUserList, setUnverifiedUserList] = useState([]);
    const [verifyUserData, setVerifyUserData] = useState({});
    const { setCompanyList } = useAuth();

    useEffect(() => {
      if (currentUser.userrole === "ADMIN") {
        const token = localStorage.getItem("token");
        async function unverifiedUserList() {
          try {
            const res = await getUnverifiedUserList();
            // console.log(res);
            setUnverifiedUserList(res);
          } catch (error) {
            if (error.response) {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.response.data.message);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.message);
            }
          }
          setLoading(false);
        }
        unverifiedUserList();
      }
    }, [openUnverifiedListDialog]);

    useEffect(() => {
      if (Object.keys(deleteUserData).length === 2) {
        const token = localStorage.getItem("token");
        async function callDeleteUserApi() {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          try {
            setLoading(true);
            const res = await axios.post(
              `${BACKEND_PROD_URL}/api/admin/deleteUser/${deleteUserData.company}`,
              deleteUserData,
              config
            );
            console.log(res);
            if (res.status === 200) {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage("User deleted successfully");
              let updatedUserList = unverifiedUserList.filter(
                (user) => user.company !== deleteUserData.company
              );
              console.log(updatedUserList);
              setUnverifiedUserList(updatedUserList);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage("Unknown error occured");
            }
          } catch (error) {
            if (error.response) {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.response.data.message);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.message);
            }
          }
          setLoading(false);
        }
        callDeleteUserApi();
        setDeleteUserData({});
      }
    }, [deleteUserData]);

    React.useEffect(() => {
      if (Object.keys(verifyUserData).length === 2) {
        const token = localStorage.getItem("token");
        async function verifyUser() {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          setLoading(true);
          try {
            const res = await axios.post(
              `${BACKEND_PROD_URL}/api/admin/verify/${verifyUserData.userEmail}`,
              verifyUserData,
              config
            );
            console.log(res);
            if (res.status === 200) {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage("User verified successfully");
              let updatedUserList = unverifiedUserList.filter(
                (user) => user.email !== verifyUserData.userEmail
              );
              console.log(updatedUserList);
              setUnverifiedUserList(updatedUserList);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage("Unknown error occured");
            }
          } catch (error) {
            if (error.response) {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.response.data.message);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.message);
            }
          }
          resetCompanyList();
        }

        async function resetCompanyList() {
          try {
            const res = await getCompanyList();
            if (res.status === 200) {
              setCompanyList(res.data);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage("Unknown error occurred");
            }
          } catch (error) {
            // console.log(error)
            if (error.response) {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.response.data.message);
            } else {
              setOpenLocalSnackbar(true);
              setLocalSnackbarMessage(error.message);
            }
          }
          setLoading(false);
        }

        verifyUser();
        setVerifyUserData({});
      }
    }, [verifyUserData]);

    const handleApproval = (email, e) => {
      e.preventDefault();
      setVerifyUserData({
        userEmail: email,
        adminEmail: currentUser.email,
      });
    };

    const handleDeleteUser = (company, e) => {
      e.preventDefault();
      setDeleteUserData({
        company: company,
        adminEmail: currentUser.email,
      });
    };

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Dialog
              fullScreen
              open={openUnverifiedListDialog}
              onClose={() => handleClose("unverifiedUsers")}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => handleClose("unverifiedUsers")}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Unverified Users
                  </Typography>
                </Toolbar>
              </AppBar>
              <div
                style={{
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  textAlign: "center",
                  margin: "10px",
                }}
              >
                <Typography variant="h6">User Approvals</Typography>
                <TableContainer
                  component={Paper}
                  style={{
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  {loading === true ? (
                    <Loading />
                  ) : (
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">mobile</TableCell>
                          <TableCell align="right">companyName</TableCell>
                          <TableCell align="right">email</TableCell>
                          <TableCell align="right">GST No</TableCell>
                          <TableCell align="right">address</TableCell>
                          <TableCell align="right">Verify</TableCell>
                          <TableCell align="right">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {unverifiedUserList.map((user) => (
                          <TableRow key={user.email}>
                            <TableCell>
                              {user.firstname + user.lastname}
                            </TableCell>
                            <TableCell align="right">{user.mobile}</TableCell>
                            <TableCell align="right">{user.company}</TableCell>
                            <TableCell align="right">{user.email}</TableCell>
                            <TableCell align="right">{user.gstno}</TableCell>
                            <TableCell align="right">{user.address}</TableCell>
                            <TableCell align="right">
                              <Button
                                onClick={(e) => handleApproval(user.email, e)}
                              >
                                Approve
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                onClick={(e) =>
                                  handleDeleteUser(user.company, e)
                                }
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </div>
            </Dialog>
          </>
        )}
        <SnackBar
          open={openLocalSnackbar}
          setOpen={setOpenLocalSnackbar}
          message={localSnackbarMessage}
          severity={severity}
        />
      </div>
    );
  };

  const ChangeUserPasswordDialog = () => {
    const [loading, setLoading] = useState(false);
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false);
    const [localSnackbarMessage, setLocalSnackbarMessage] = useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [passwordtwo, setPasswordtwo] = React.useState("");

    const handleSubmit = async (event) => {
      console.log("Calling change password");
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      console.log(data.get("email"), password, passwordtwo);
      if (password !== passwordtwo) {
        console.log("Password not matched");
        setOpenLocalSnackbar(true);
        setLocalSnackbarMessage("Password Mismatched");
      } else {
        const passwordChangeData = {
          email: data.get("email"),
          password: password,
          adminEmail: currentUser.email,
        };
        setLoading(true);
        try {
          const res = await updateUserPassword(passwordChangeData);
          console.log(res);
          if (res.status === 200) {
            setOpenLocalSnackbar(true);
            setLocalSnackbarMessage("Password changed successfully");
          } else {
            setOpenLocalSnackbar(true);
            setLocalSnackbarMessage("Unknown error occured");
          }
        } catch (error) {
          if (error.response) {
            setOpenLocalSnackbar(true);
            setLocalSnackbarMessage(error.response.data.message);
          } else {
            setOpenLocalSnackbar(true);
            setLocalSnackbarMessage(error.message);
          }
        }
        setLoading(false);
      }
    };

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Dialog
              fullScreen
              open={openChangeUserPasswordDialog}
              onClose={() => handleClose("changePassword")}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => handleClose("changePassword")}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Notify User
                  </Typography>
                </Toolbar>
              </AppBar>
              <div
                style={{
                  padding: "10px",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6">Change Password</Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <TextField
                    style={{ margin: "10px" }}
                    margin="normal"
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                  />
                  <div style={{ margin: "10px" }}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((show) => !show)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onInput={(event) => setPassword(event.target.value)}
                      />
                    </FormControl>
                  </div>
                  <div style={{ margin: "10px" }}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="password2"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((show) => !show)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onInput={(event) => setPasswordtwo(event.target.value)}
                      />
                    </FormControl>
                  </div>
                  <Button
                    style={{ margin: "10px" }}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Change Password
                  </Button>
                </Box>
              </div>
            </Dialog>
          </>
        )}
        <SnackBar
          open={openLocalSnackbar}
          setOpen={setOpenLocalSnackbar}
          message={localSnackbarMessage}
          severity={severity}
        />
      </div>
    );
  };

  const DeleteNotificationDialog = () => {
    const [selectedUser, setSelectedUser] = useState("");
    const [notificationList, setNotificationList] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState("");
    const [loading, setLoading] = useState(false);
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false);
    const [localSnackbarMessage, setLocalSnackbarMessage] = useState("");
    const [selectedCompany, setSelectedCompanies] = useState([]);
    const [selectedNotificationId, setSelectedNotificationId] = useState("");

    useEffect(() => {
      const fetchCompanyDetails = async () => {
        setLoading(true);
        try {
          let companies = await getCompanies({ email: currentUser.email });
          companies = [{ company: "All" }, ...companies];
          setCompaniesList(companies);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage("Failed to fetch Users");
        }
      };

      if (openDeleteNotificationDialog) {
        fetchCompanyDetails();
      }
    }, [openDeleteNotificationDialog]);

    useEffect(() => {
      async function fetchNotifications() {
        const token = localStorage.getItem("token");
        const configs = {
          headers: { Authorization: `Bearer ${token}` },
        };

        try {
          const res = await axios.get(
            `${BACKEND_PROD_URL}/api/admin/getNotification`,
            configs
          );
          console.log(res.data);
          setNotificationList(res.data);
        } catch (error) {
          console.error(error);
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage("Failed to fetch notifications");
        }
      }
      fetchNotifications();
    }, []);

    const handleDeleteNotification = async () => {
      if (selectedCompany.length === 0 || !selectedNotificationId) {
        setLocalSnackbarMessage(
          "Please select a company & notification before deleting"
        );

        setOpenLocalSnackbar(true);
        return;
      }

      let selectedCompanies = selectedCompany.includes("All")
        ? [...companiesList.map((c) => c.company), currentUser.company]
        : selectedCompany;

      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const configs = {
          headers: { Authorization: `Bearer ${token}` },
        };

        await axios.post(
          `${BACKEND_PROD_URL}/api/admin/deleteNotifition`,
          {
            notificationId: selectedNotificationId,
            selectedCompanylist: selectedCompanies,
          },
          configs
        );
        setOpenLocalSnackbar(true);
        setLocalSnackbarMessage("Notification deleted successfully");
        setNotificationList(
          notificationList.filter(
            (notification) => notification._id !== selectedNotificationId
          )
        );
        setSelectedCompanies([]);
        setSelectedNotification(null);
        setSelectedNotificationId("");
      } catch (error) {
        setOpenLocalSnackbar(true);
        setLocalSnackbarMessage("Failed to delete notification");
      } finally {
        setLoading(false);
      }
      handleClose("deleteNotification");
    };

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Dialog
              fullScreen
              open={openDeleteNotificationDialog}
              onClose={() => handleClose("deleteNotification")}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => handleClose("deleteNotification")}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Delete Notification
                  </Typography>
                  <Button
                    autoFocus
                    color="inherit"
                    onClick={handleDeleteNotification}
                  >
                    Delete
                  </Button>
                </Toolbar>
              </AppBar>

              <Autocomplete
                multiple
                id="emails"
                options={
                  companiesList &&
                  companiesList?.map((option) => option.company)
                }
                value={selectedCompany}
                freeSolo
                onChange={(event, newValue) => {
                  setSelectedCompanies(newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Users"
                    placeholder="Select Users"
                  />
                )}
                sx={{ margin: 2 }}
              />
              <Autocomplete
                id="notification-autocomplete"
                sx={{ mx: 2, flex: 1 }}
                options={notificationList}
                placeholder="Search Notifications"
                getOptionLabel={(option) => option.message}
                value={selectedNotification ? selectedNotification : null}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setSelectedNotification(newValue);
                  setSelectedNotificationId(newValue._id);
                }}
                renderInput={(p) => (
                  <TextField
                    {...p}
                    key={p._id}
                    label="Notifications"
                    placeholder={"Search Notifications"}
                  />
                )}
              />
            </Dialog>
          </>
        )}
        <SnackBar
          open={openLocalSnackbar}
          setOpen={setOpenLocalSnackbar}
          message={localSnackbarMessage}
          severity={severity}
        />
      </div>
    );
  };

  const EditUserDialog = () => {
    const [loading, setLoading] = useState(false);
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false);
    const [localSnackbarMessage, setLocalSnackbarMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordtwo, setPasswordtwo] = useState("");
    const [companyDetails, setCompanyDetails] = useState([]);
    const [companyDetail, setCompanyDetail] = useState();
    const [companyName, setCompanyName] = useState("");
    const formRef = React.useRef(null);

    React.useEffect(() => {
      async function getCompanyDetails() {
        const token = localStorage.getItem("token");
        const configs = {
          headers: { Authorization: `Bearer ${token}` },
        };
        try {
          const res = await axios.get(
            `${BACKEND_PROD_URL}/api/admin/getCompanyDetails`,
            configs
          );
          // console.log(res.data);
          setCompanyDetails(res.data);
        } catch (error) {
          // console.log(error)
          if (error.response) {
            setOpenLocalSnackbar(true);
            setLocalSnackbarMessage(error.response.data.message);
          } else {
            setOpenLocalSnackbar(true);
            setLocalSnackbarMessage(error.message);
          }
        }
      }
      if (currentUser.userrole === "ADMIN") getCompanyDetails();
    }, [openEditUserDialog]);

    const handleEditUser = () => {
      console.log(companyName);
      for (let i = 0; i < companyDetails.length; i++) {
        if (companyDetails[i].company === companyName) {
          console.log(companyDetails[i]);
          setCompanyDetail(companyDetails[i]);
          break;
        }
      }
      console.log(companyDetail);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(formRef.current);
      const editUserCompanyData = {
        id: companyDetail._id,
        firstname: data.get("firstName"),
        lastname: data.get("lastName"),
        mobile: data.get("mobileNo"),
        email: data.get("emailUser"),
        company: data.get("company"),
        address: data.get("address"),
        gstno: data.get("gstno"),
      };
      console.log(editUserCompanyData);
      try {
        const res = await editCompanyDetails(editUserCompanyData);
        if (res.status === 200) {
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage("User information updated successfully");
        } else {
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage("Unknown error occurred");
        }
      } catch (error) {
        if (error.response) {
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage(error.response.data.message);
        } else {
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage(error.message);
        }
      }
    };

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Dialog
              fullScreen
              open={openEditUserDialog}
              onClose={() => handleClose("editUser")}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => handleClose("editUser")}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Notify User
                  </Typography>
                </Toolbar>
              </AppBar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6">Edit User</Typography>
                  <div
                    style={{
                      margin: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {companyDetails.length === 0 ? (
                      <Skeleton variant="rounded" height={60} />
                    ) : (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={companyDetails.map(
                          (company) => company.company
                        )}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Company Name" />
                        )}
                        value={companyName}
                        onChange={(event, newValue) => {
                          setCompanyName(newValue);
                        }}
                      />
                    )}
                    <Button
                      style={{ margin: "10px", width: "200px" }}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleEditUser}
                    >
                      Edit Details
                    </Button>
                    {companyDetail !== null && companyDetail !== undefined ? (
                      <form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        sx={{ mt: 5 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="firstName"
                              required
                              fullWidth
                              id="firstName"
                              label="First Name"
                              defaultValue={companyDetail.firstname}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              fullWidth
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                              defaultValue={companyDetail.lastname}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              fullWidth
                              id="emailUser"
                              label="Email Address"
                              name="emailUser"
                              defaultValue={companyDetail.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              fullWidth
                              name="mobileNo"
                              label="Mobile Number"
                              id="mobileNo"
                              defaultValue={companyDetail.mobile}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              required
                              fullWidth
                              name="gstno"
                              label="GST Number"
                              id="gstno"
                              defaultValue={companyDetail.gstno}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              required
                              fullWidth
                              name="address"
                              label="Address"
                              id="address"
                              defaultValue={companyDetail.address}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              name="company"
                              label="Company Name"
                              id="company"
                              defaultValue={companyDetail.company}
                            />
                          </Grid>
                        </Grid>

                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={handleSubmit}
                        >
                          Submit Details
                        </Button>
                      </form>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </Dialog>
          </>
        )}
        <SnackBar
          open={openLocalSnackbar}
          setOpen={setOpenLocalSnackbar}
          message={localSnackbarMessage}
          severity={severity}
        />
      </div>
    );
  };

  
  const ReprocessFailedQrsDialog = () => {
    const [loading, setLoading] = useState(false);
    const [openLocalSnackbar, setOpenLocalSnackbar] = useState(false);
    const [localSnackbarMessage, setLocalSnackbarMessage] = useState("");
    const [successfulBatches, setSuccessfulBatches] = useState([]);
    const [failedBatches, setFailedBatches] = useState([]);
    const [stillPendingMessage, setStillPendingMessage] = useState("");
    const [reprocessQrsTableSize, setReprocessQrsTableSize] = useState(-1);
  
    // ✅ Fetch the reprocess table size when the dialog opens
    useEffect(() => {
      async function getReprocessQrsTableSize() {
        setLoading(true);
        try {
          const res = await getReprocessTableSize();
          setReprocessQrsTableSize(res);
        } catch (error) {
          console.error(error);
          setOpenLocalSnackbar(true);
          setLocalSnackbarMessage(error.response?.data?.message || error.message);
        } finally {
          setLoading(false);
        }
      }
      if (openFailedReprocessQrsDialog) getReprocessQrsTableSize();
    }, [openFailedReprocessQrsDialog]);
  
    // ✅ Reprocessing function (does not trigger re-render)
    const handleReprocessFailedBatches = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const configs = { headers: { Authorization: `Bearer ${token}` } };
  
        const res = await axios.get(`${BACKEND_PROD_URL}/api/qrcode/reprocessFailedQrs`, configs);
  
        // ✅ Update UI with response data
        setReprocessQrsTableSize(res.data.totalPending);
        setSuccessfulBatches(res.data.successfulBatches || []);
        setFailedBatches(res.data.failedBatches || []);
        setStillPendingMessage(res.data.stillPendingBatches);
  
        setLocalSnackbarMessage(`Reprocessed ${res.data.totalReprocessed} QR codes successfully.`);
      } catch (error) {
        console.error(error);
        setOpenLocalSnackbar(true);
        setLocalSnackbarMessage(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Dialog
        fullScreen
        open={openFailedReprocessQrsDialog}
        onClose={() => handleClose("reprocessFailedQrs")}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose("reprocessFailedQrs")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Reprocess Failed QR Codes
            </Typography>
          </Toolbar>
        </AppBar>
  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Reprocess the QR codes. Current Total Count: <strong>{reprocessQrsTableSize}</strong>
          </Typography>
  
          {/* ✅ Show the loading inside the dialog, not outside */}
          {loading ? (
            <div style={{ marginTop: "20px" }}>
              <Loading />
              <Typography variant="body1">Processing...</Typography>
            </div>
          ) : (
            <>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={handleReprocessFailedBatches}
                style={{ width: "200px", marginBottom: "20px" }}
              >
                Reprocess
              </Button>
  
              {/* ✅ Display successful batches */}
              {successfulBatches.length > 0 && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Typography variant="h6" color="green">
                    Successfully Reprocessed Batches:
                  </Typography>
                  <ul style={{ textAlign: "left" }}>
                    {successfulBatches.map((batch, index) => (
                      <li key={index}>
                        Batch {index + 1}: <strong>{batch.batchStartSeqNo}</strong> - <strong>{batch.batchEndSeqNo}</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
  
              {/* ✅ Display failed batches */}
              {failedBatches.length > 0 && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Typography variant="h6" color="red">
                    Failed Batches (Needs Reprocessing):
                  </Typography>
                  <ul style={{ textAlign: "left", color: "red" }}>
                    {failedBatches.map((batch, index) => (
                      <li key={index}>
                        Batch {index + 1}: <strong>{batch.batchStartSeqNo}</strong> - <strong>{batch.batchEndSeqNo}</strong>
                      </li>
                    ))}
                  </ul>
                  <Typography style={{ color: "red", fontWeight: "bold" }}>
                    {stillPendingMessage}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
  
        <SnackBar
          open={openLocalSnackbar}
          setOpen={setOpenLocalSnackbar}
          message={localSnackbarMessage}
          severity={successfulBatches.length > 0 ? "success" : "error"}
        />
      </Dialog>
    );
  };
  

  return (
    <UserGuard>
      <AdminAuth>
        <Topbar />
        <div style={{ padding: "20px" }}>
          {parentLoading ? (
            <Loading />
          ) : (
            <>
              <Stack direction="column" spacing={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  endIcon={<SendIcon />}
                  style={{ height: "50px" }}
                  onClick={() => handleClickOpen("notifyUser")}
                >
                  Notify Subscriber
                </Button>
                <Button
                  variant="outlined"
                  style={{ height: "50px" }}
                  endIcon={<SendIcon />}
                  onClick={() => handleClickOpen("deleteNotification")}
                >
                  Delete notifications
                </Button>
                <Button
                  variant="outlined"
                  style={{ height: "50px" }}
                  endIcon={<SendIcon />}
                  onClick={() => handleClickOpen("editUser")}
                >
                  Edit User Information
                </Button>
                <Button
                  variant="outlined"
                  style={{ height: "50px" }}
                  endIcon={<SendIcon />}
                  onClick={() => handleClickOpen("changePassword")}
                >
                  Change User Password
                </Button>
                <Badge
                  badgeContent={unverifiedUserCount}
                  color="success"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  overlap="rectangular"
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    endIcon={<SendIcon />}
                    style={{ height: "50px" }}
                    onClick={() => handleClickOpen("unverifiedUsers")}
                  >
                    New Users List
                  </Button>
                </Badge>
                <Button
                  variant="outlined"
                  style={{ height: "50px" }}
                  endIcon={<SendIcon />}
                  onClick={() => handleClickOpen("reprocessFailedQrs")}
                >
                  Reprocess Failed Qrs
                </Button>
              </Stack>
            </>
          )}
          <NotifyUserDialog />
          <DeleteNotificationDialog />
          <UnverifiedUserListDialog />
          <ChangeUserPasswordDialog />
          <EditUserDialog />
          <ReprocessFailedQrsDialog />
        </div>
        <SnackBar
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={severity}
        />
      </AdminAuth>
    </UserGuard>
  );
};

export default AdminUtils;
