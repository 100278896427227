import axios from "axios";
import { BACKEND_PROD_URL } from "../config";
const token = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

export const getCompanies = async (data) => {
  // console.log(data)
  const compaines = await axios.post(
    `${BACKEND_PROD_URL}/api/admin/getCompaniesList`,
    data,
    config
  );
  // console.log(compaines)
  return compaines.data;
};

export const notifyUsers = async (data) => {
  console.log(data);
  const emails = await axios.post(
    `${BACKEND_PROD_URL}/api/admin/notifyUsers`,
    data,
    config
  );
  // console.log(emails);
  return emails.data;
};

export const getUnverifiedUserList = async () => {
  const res = await axios.get(
    `${BACKEND_PROD_URL}/api/admin/unverifiedUserList`,
    config
  );
  return res.data;
};

export const getCompanyList = async () => {
  const res = await axios.get(
    `${BACKEND_PROD_URL}/api/admin/getCompanyList`,
    config
  );
  return res.data;
};

export const updateUserPassword = async (data) => {
  const res = await axios.post(
    `${BACKEND_PROD_URL}/api/admin/changePassword`,
    data,
    config
  );
  return res;
};

export const editCompanyDetails = async (data) => {
  const res = await axios.post(
    `${BACKEND_PROD_URL}/api/admin/updateUserDetails`,
    data,
    config
  );
  return res;
};

export const getReprocessTableSize = async () => {
  const res = await axios.get(
    `${BACKEND_PROD_URL}/api/qrcode/getReprocessQrsNumber`,
    config
  );
  return res.data;
};